.circle-red {
    width: 10px;
    height: 10px;
    background:#ff2e34;
    border-radius: 50%;
    display:inline-block;
  }


.circle-yellow {
    width: 10px;
    height: 10px;
    background:#ffcd00;
    border-radius: 50%;
    display:inline-block;
  }

.circle-green {
    width: 10px;
    height: 10px;
    background:#00aa53;
    border-radius: 50%;
    display:inline-block;
  }