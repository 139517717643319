.column-space {
    margin:auto;
    width: 100%;
    padding:1% 0%;
    align-items: center !important;
    justify-content: center !important;
}

.column-red {
    background-color: #ff2e34 !important;    
    border: 0 !important;   
}

.column-green {
    background-color: #00aa53  !important;    
    border: 0 !important;    
}

.column-yellow {
    background-color: #ffcd00 !important;    
    border: 0 !important;
}
.zoom:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.mont-med{
    font-family: Montserrat-Medium;
    color: black !important;
    width: 90%;
    height: 110%;
    border-radius: 9px !important;
    font-size: 125% !important;
    outline: none !important;
    box-shadow: none !important;
}
.card-mod{
    margin-left: 1% !important;
    border-radius: 0px !important;
}
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }

 .row-eq-height {
    
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  
  }

  #transp {
    position:absolute;
    right:0;
    float:right;
    background: rgba(0,0,0,.7);
    width:50%;
    height:100%;
    padding:20% 13% 20% 7%;
    color: white;
}
  #transpreci {
    position:absolute;
    right:0;
    float:right;
    background: rgba(0,0,0,.7);
    width:58%;
    height:100%;
    padding:2% 5%;
    color: white;
}

.slide{
    transform: scale(0.8);
    transition: transform 300ms;
}

.activeSlide{
    transform: scale(1.1);
    opacity:1;
}


.debugReci{
    border:10px solid transparent;
}