

.about{
    font-family: 'second';
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

div[class*=box] {
    height: 33.33%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    /* line-height: 50px; */
    /* height: 50px; */
    text-align: center;
    /* width: 100px; */
    cursor: pointer;
}

.btn-one {
    color: black;
    transition: all 0.1s;
    position: relative;
}


.btn-one span {
    transition: all 0.1s;
}
.btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.1s;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: red;
    border-bottom-color: black;
    transform: scale(0, 1);
}


.btn-one:hover div div  {
    /* letter-spacing: 1px; */
    transform: scale(1.75);
}

.btn-one:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}

.btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
}

.btn-one:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
} 