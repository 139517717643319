/* :target {
    display: block;    
    position: relative;     
    top: -75px;
    visibility: hidden;
} */

*[id]:before { 
    display: block; 
    content: " "; 
    margin-top: -75px; 
    height: 75px;
    visibility: hidden; 
  }