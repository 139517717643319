.largesize{
    font-size: 200%;
}

*[id]:before { 
    display: block; 
    content: " "; 
    margin-top: -75px; 
    height: 75px;
    visibility: hidden; 
  }